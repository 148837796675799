import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const Fifa = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { fifaCloudinary } = useStaticQuery(graphql`
    query {
      fifaCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/fifa/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>01. Fifa World Cup 2026</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Identity, Typeface Design</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-fifa">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/fifa-museum">Next Project(02) ↗</Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>FIFA WORLD CUP 2026 UNITED STATES </p>
            </div>
            <div className="col-lg-7">
              <p>
                The visual identity is in reference to the magic of soccer. Many
                professional soccer players have spoken of their joy playing the
                sport. Can the experience of playing be visually represented and
                include magical moments such as extending someone’s arms
                drastically or legs?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607042538/rgrq/static/fifa/02_tgbyf2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Typography</p>
            </div>
            <div className="col-lg-7">
              <p>
                The typography references many vernacular street typography and
                type used in soccer throughout history within elements such as
                posters and jerseys. The logo uses dots as a form of play and
                rounding the angles to humanize the letterforms of the wordmark.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607042502/rgrq/static/fifa/04_kcitxv.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>

            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>

            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607042547/rgrq/static/fifa/18_fb7hjt.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>

            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607042533/rgrq/static/fifa/19_e1mcn5.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[23].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[24].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[25].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[26].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[27].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[28].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[29].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[30].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[31].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[32].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={fifaCloudinary.edges[33].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607042511/rgrq/static/fifa/39_mfeoi3.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Brad Bartlett (Professor)</p>
              <p>Sharon Park (TA)</p>
              <p>Chris Taylor (TA)</p>
              <p>Cristopher Gonzalez</p>
              <p>Mazzie Miles (Professor)</p>
              <p>Ivan Cruz (Professor)</p>
              <p>Mario Ascencio</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Fifa
